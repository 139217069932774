export const validator = {
    hasError: true,
    username: {
        error_show: false,
        error_msg: null
    },
    email: {
        error_show: false,
        error_msg: null
    },
    org_id: {
        error_show: false,
        error_msg: null
    },
    status: {
        error_show: false,
        error_msg: null
    },
    password: {
        error_show: false,
        error_msg: null
    },
    new_pass: {
        error_show: false,
        error_msg: null
    },
    re_new_pass: {
        error_show: false,
        error_msg: null
    },
}

export const newItem = {
    username: null,
    email: null,
    org_id: null,
    status: null,
    password: null,
    new_pass: null,
    re_new_pass: null
}
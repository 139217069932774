<template>
  <div>
    <v-row>
      <v-col cols="12">
        <Table
          :servicePath="'admin/user/list'"
          :headers="tableHeaders"
          :title="tableTitle"
          :itemsPerPageSent="itemsPerPageSent"
          :sortByKey="sortBy"
          :sortDesc="sortDesc"
          icon="mdi-account-multiple"
          tableType="customers"
          :reload="reload"
          v-on:addUser="addUserDialog = true"
          v-on:editUser="edit"
          v-on:closePopup="addUserDialog = false"
        />
      </v-col>
    </v-row>
    <AddUser
      :popupAddUser="addUserDialog"
      v-on:userAdded="reload = reload ? false : true; closePopup;"
      v-on:closePopup="closePopup"
    />
    <EditUser 
      :popupEditUser="editUserDialog"
      :user="currentUser"
      v-on:userUpdated="reload = reload ? false : true; closePopup;"
      v-on:closePopup="closePopup"
    />
  </div>
</template>

<script>
import Table from "@/components/shared/Table";
import AddUser from "@/components/manager/popup/AddUser";
import EditUser from "@/components/manager/popup/EditUser";
export default {
  components: {
    Table,
    AddUser,
    EditUser
  },
  data: function() {
    return {
      currentUser: false,
      reload: false,
      addUserDialog: false,
      editUserDialog: false,
      sortBy: ["date"],
      sortDesc: [true],
      itemsPerPageSent: 50,
      fieldClickable: {
        field: "org_name",
        to: "/manager/company/list",
        key: "company_id",
      },
    };
  },
  methods: {
    closePopup() {
      this.addUserDialog = false;
      this.editUserDialog = false;
    },
    edit(user){
      this.currentUser = user;
      this.editUserDialog = true
    }
  },
  computed: {
    tableTitle() {
      return this.$vuetify.lang.t("$vuetify.navigations.users");
    },
    tableHeaders() {
      return [
        {
          text: this.$vuetify.lang.t("$vuetify.manager.username"),
          value: "username",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.manager.email"),
          value: "email",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.manager.status"),
          value: "status",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.manager.role"),
          value: "role",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.tables.company"),
          value: "companies",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.manager.login_date"),
          value: "login_date",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.manager.reg_date"),
          value: "insert_date",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.action.action"),
          value: "editUser",
          sortable: false
        }
      ];
    },
  },
};
</script>

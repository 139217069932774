<template>
  <v-dialog v-model="dialogState" persistent max-width="600px">
    <v-card>
      <v-toolbar color="accent" fixed>
        <v-toolbar-title>
          <v-icon left class="mt-n1">mdi-account-edit</v-icon>
          {{ $vuetify.lang.t("$vuetify.manager.update_customer") }}
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form autocomplete="off">
          <v-row align="start">
            <div class="col-xs-12 col-sm-12 col-md-6">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.manager.username') + ' *'"
                v-model="userData.username"
                :error="validator.username.error_show"
                :error-messages="validator.username.error_msg"
                @keyup="validateForm"
              ></v-text-field>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-6">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.manager.email') + ' *'"
                v-model="userData.email"
                :error="validator.email.error_show"
                :error-messages="validator.email.error_msg"
                @keyup="validateForm"
              ></v-text-field>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-12">
              <v-select
                :label="$vuetify.lang.t('$vuetify.manager.status') + ' *'"
                :items="statuses"
                v-model="userData.status_key"
                :error="validator.status.error_show"
                :error-messages="validator.status.error_msg"
                @keyup="validateForm"
                @change="validateForm"
                item-text="value"
                item-value="key"
              >
                <template slot="selection" slot-scope="statuses">{{
                  statuses.item.value
                }}</template>
                <template slot="item" slot-scope="statuses">{{
                  statuses.item.value
                }}</template>
              </v-select>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-6">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.manager.new_pass')"
                v-model="userData.new_pass"
                :error="validator.new_pass.error_show"
                :error-messages="validator.new_pass.error_msg"
                @keyup="validateForm"
                type="password"
              ></v-text-field>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-6">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.manager.password_confirm')"
                v-model="userData.re_new_pass"
                :error="validator.re_new_pass.error_show"
                :error-messages="validator.re_new_pass.error_msg"
                @keyup="validateForm"
                type="password"
              ></v-text-field>
            </div>

            <v-autocomplete
                v-model="userCompanies"
                :items="organisations"
                item-text="text"
                item-value="code"
                return-object
                :label="$vuetify.lang.t('$vuetify.tables.company')+ ' *'"
                :error-messages="validator.org_id.error_msg"
                @change="validateForm"
                multiple
                chips
              >
                <template v-slot:selection="data">
                  <v-chip
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    close
                    @click:close="removeCompany(data.item.id)"
                  >
                    {{ data.item.text }}
                  </v-chip>
                </template>
            </v-autocomplete>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="closePopup">
          <v-icon class="mr-3">mdi-close</v-icon>
          {{ $vuetify.lang.t("$vuetify.action.cancel") }}
        </v-btn>
        <v-btn @click="updateUser" :disabled="validator.hasError">
          <v-icon class="mr-3">mdi-content-save</v-icon>
          {{ $vuetify.lang.t("$vuetify.action.update") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Api from "@/services/api";
import UserHelper from "@/helpers/user";
export default {
  data: function() {
    return {
      dialogState: false,
      userData: [],
      validator: UserHelper.validatorModel(),
      organisations: [],
      statuses: [],
      userCompanies: []
    };
  },
  methods: {
    removeCompany(companyId) {
      this.userCompanies = this.userCompanies.filter(t => t.id !== companyId);
      this.validateForm()
    },
    validateForm() {
      this.validator = UserHelper.validateForm(
        this.userData.username,
        this.userData.email,
        'ignore',
        this.userCompanies,
        this.userData.new_pass,
        this.userData.re_new_pass
      );

      console.log(this.userCompanies);
    },
    closePopup() {
      this.dialogState = false;
      this.$emit("closePopup");
      this.validator = UserHelper.validatorModel();
      this.userCompanies = [];
    },
    updateUser() {
      this.dialogState = false;
      this.userData.role = this.userData.role_key
      this.userData.status = this.userData.status_key

      if(this.userData.new_pass && this.userData.new_pass!='' && this.userData.new_pass!=null) {
        this.userData.password = this.userData.new_pass;
        delete this.userData.new_pass
        delete this.userData.re_new_pass
      }

      if(this.userCompanies) {
        this.userData.companies = this.userCompanies.map((t) => t.id);
      }

      Api.manager("update", this.userData).then(({ data }) => {
        this.$toast.ok(this.$vuetify.lang.t("$vuetify.action.update_success"));
        this.$emit("userUpdated", data);
      });
      this.closePopup();
    },
  },
  watch: {
    popupEditUser(newVal) {
      this.dialogState = newVal;
      
      if(this.user) {
        this.userCompanies = [];
        let v = this;
        this.user.companies.map(function(t){
          let name = t.name[v.$i18n.locale].trim();
          v.userCompanies.push({id: t.id, text: name}); 
        });
      }
    },
    user(newVal) {
      this.userData = JSON.parse(JSON.stringify(newVal));
      this.validator.hasError = true;
      if(typeof this.userData['companies'][0] != "undefined") {
        this.userData.org_id = this.userData['companies'][0]['name'][this.$i18n.locale];
        this.selected = this.userData['companies'][0]['id']
      }
      this.statuses = this.$i18n["messages"][this.$i18n.locale]["$vuetify"][
        "statuses"
      ];
    },
    "userCompanies"() {
      this.validateForm()
    },
  },
  props: ["popupEditUser", "user"],
  beforeCreate() {
    Api.company("names").then(({ data }) => {
      for (const [k] of Object.entries(data)) {
        let name = data[k]['name'][this.$i18n.locale].trim();
        name = name.replace(/\s+-\s+\d+$/, '');

        this.organisations.push({'text': name,'id':data[k]['id']})
      }
    });
  },
};
</script>

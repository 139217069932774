<template>
  <v-dialog v-model="dialogState" persistent max-width="800px">
    <v-card>
      <v-toolbar color="accent" fixed>
        <v-toolbar-title>
          <v-icon left class="mt-n1">mdi-account-plus</v-icon>
          {{$vuetify.lang.t('$vuetify.manager.create_user')}}
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form>
          <v-row align="start">
            <div class="col-xs-12 col-sm-12 col-md-6">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.manager.username')+ ' *'"
                v-model="newItem.username"
                :error="validator.username.error_show"
                :error-messages="validator.username.error_msg"
                @keyup="validateForm"
              ></v-text-field>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-6">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.manager.email')+ ' *'"
                v-model="newItem.email"
                :error="validator.email.error_show"
                :error-messages="validator.email.error_msg"
                @keyup="validateForm"
              ></v-text-field>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-6">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.input.password')+ ' *'"
                v-model="newItem.password"
                :error="validator.password.error_show"
                :error-messages="validator.password.error_msg"
                @keyup="validateForm"
              ></v-text-field>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-6">
              <v-autocomplete
                v-model="newItem.org_id"
                :items="organisations"
                item-text="text"
                item-value="code"
                return-object
                :label="$vuetify.lang.t('$vuetify.tables.company')+ ' *'"
                :error-messages="validator.org_id.error_msg"
                @change="validateForm"
                multiple
                chips
              >
                <template v-slot:selection="data">
                  <v-chip
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    close
                    @click:close="remove(data.item.id)"
                  >
                    {{ data.item.text }}
                  </v-chip>
                </template>
            </v-autocomplete>
            </div>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="closePopup">
          <v-icon class="mr-3">mdi-close</v-icon>
          {{$vuetify.lang.t('$vuetify.action.cancel')}}
        </v-btn>
        <v-btn @click="createUser" :disabled="validator.hasError">
          <v-icon class="mr-3">mdi-content-save</v-icon>
          {{$vuetify.lang.t('$vuetify.action.create')}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Api from "@/services/api";
import UserHelper from "@/helpers/user";
export default {
  data: function() {
    return {
      dialogState: false,
      newItem: UserHelper.newItemModel(),
      validator: UserHelper.validatorModel(),
      organisations: []
    };
  },
  methods: {
    remove(companyId) {
      this.newItem.org_id = this.newItem.org_id.filter(t => t.id !== companyId);
    },
    validateForm() {
      this.validator = UserHelper.validateForm(
        this.newItem.username,
        this.newItem.email,
        this.newItem.password,
        this.newItem.org_id
      );
    },
    closePopup() {
      this.dialogState = false;
      this.$emit("closePopup");
      this.validator = UserHelper.validatorModel();
      this.newItem = UserHelper.newItemModel();
    },
    createUser() {
      this.newItem.role = 'customer';
      this.newItem.status = 'active'

      if(this.newItem.org_id) {
        this.newItem.companies = this.newItem.org_id.map((t) => t.id);
      }

      Api.manager("create", this.newItem)
        .then(({ data }) => {
          this.$toast.ok(
            this.$vuetify.lang.t("$vuetify.manager.account_created"));

          this.$emit("userAdded", data);
          this.closePopup();
        })
        .catch(error => {
          if (
            error.response.status == 400 &&
            typeof error.response.data.field !== "undefined"
          ) {
            this.validator.username.error_show = true;
            this.validator.username.error_msg = this.$i18n.messages[
              this.$i18n.locale
            ]["$vuetify"]["service_field_errors"][error.response.data.error];
          }
        });
    }
  },
  watch: {
    popupAddUser: function(newVal) {
      this.dialogState = newVal;
    },
  },
  beforeCreate() {
      Api.company("names")
        .then(({ data }) => {
          for (const [k] of Object.entries(data)) {
            this.organisations.push({'text':data[k]['name'][this.$i18n.locale],'id':data[k]['id']})
          }
        });
  },
  props: ["popupAddUser"]
};
</script>
import vm from '@/main.js'
import * as HelperModel from '@/models/user.js'

class UserHelpers {

    validatorModel() {
        return JSON.parse(JSON.stringify(HelperModel.validator))
    }

    newItemModel() {
        return JSON.parse(JSON.stringify(HelperModel.newItem))
    }

    validateForm(name, email, password, org_id, np, rnp) {
        var validator = this.validatorModel()
        if (name === null || name.length <= 0) {
            validator.username.error_show = true;
            validator.username.error_msg = vm.$vuetify.lang.t(
                "$vuetify.errors.required"
            );
            validator.hasError = true;
        } else {
            validator.username.error_show = false;
            validator.username.error_msg = null;
            validator.hasError = false;
        }
        
        if (password === null || password.length <= 0) {
            validator.password.error_show = true;
            validator.password.error_msg = vm.$vuetify.lang.t(
                "$vuetify.errors.required"
            );
            validator.hasError = true;
        } else {
            validator.password.error_show = false;
            validator.password.error_msg = null;
            validator.hasError = false;
        }

        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z0-9]{2,}))$/;
        if (email === null || email.length <= 0 || !re.test(email)) {
            validator.email.error_show = true;
            validator.email.error_msg = vm.$vuetify.lang.t(
                "$vuetify.errors.required"
            );
            validator.hasError = true;
        } else {
            validator.email.error_show = false;
            validator.email.error_msg = null;
            validator.hasError = validator.hasError ? true : false;
        }

        if(np!=null && np!=rnp) {
            validator.re_new_pass.error_show = true;
            validator.re_new_pass.error_msg = vm.$vuetify.lang.t(
                "$vuetify.errors.dont_match"
            );
            validator.hasError = true;
        }

        return validator;
    }

}

const UserHelper = new UserHelpers
export default UserHelper